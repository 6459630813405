<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/tab3"></ion-back-button>
        </ion-buttons>
        <ion-title>Feedback</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Feedback</ion-title>
        </ion-toolbar>
      </ion-header>

<p>...Feedback...</p>


    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle,
IonContent, IonBackButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tab3Feedback',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
  IonButtons, IonBackButton }
})
</script>
