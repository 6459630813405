
import { IonPage, IonButtons, IonHeader, IonToolbar, IonTitle,
IonContent, IonBackButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tab3Feedback',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
  IonButtons, IonBackButton }
})
